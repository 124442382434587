
import { Component, Mixins, Prop, Emit, Watch } from "vue-property-decorator";
import { ValidationProvider } from "vee-validate";
import { HasProperyPart } from "@/mixins/has-property-part";

@Component({
    components: {
        InputRadio: () => import("@/components/forms/property-radio.vue"),
        ValidationProvider,
    },
})
export default class PropertyPartBuildingType extends Mixins(HasProperyPart) {
    @Prop({ default: null }) value!: "OB" | "HOB" | "GB" | null;

    options = ["OB", "HOB", "GB"];

    localValue: "OB" | "HOB" | "GB" | null = null;

    @Emit("input")
    handleInput(v: "OB" | "HOB" | "GB" | null) {
        return this.localValue;
    }

    @Watch("value", { immediate: true })
    valueChanged(newValue: "OB" | "HOB" | "GB" | null) {
        this.localValue = newValue;
    }
}
